import { styled } from '@abyss/web/tools/styled';
import { FaUserCircle } from 'react-icons/fa';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Popover } from '@abyss/web/ui/Popover';
import { pxToRem } from '../../../utils/formatFontSize';
import { PageLayout } from '../../../components/PageLayout';
import PRELogo from '../../../assets/svg/new_pre_logo.svg';
import useAuth from '../../../hooks/useAuth';
import { isDMZ } from '../../../utils/commonUtil';

const HeaderStyle = styled('div', {
  height: '88px',
  'box-shadow': `0 5px 5px 0 rgba(0, 0, 0, 0.05)`,
  'borderRadius': '3px',
  'borderBottom': '1px solid',
  'borderColor': `$UHC_GRAY_MEDIUM`,
  'background-color': 'white'
});

const UserIcon = styled(FaUserCircle, {
  color: `$UHC_BLUE`,
  height: `${pxToRem(20)}`,
  width: `${pxToRem(20)}`
});

const DropdownIcon = styled(RiArrowDownSLine, {
  color: `$UHC_BLUE`,
  height: `${pxToRem(20)}`,
  width: `${pxToRem(20)}`
});

const NavBar = styled('nav', {
  display: 'flex',
  'align-items': 'left',
  'justify-content': 'space-between',
  height: '80px'
});


const UHC = styled('img', {
  height: '78px'
});

const NavLeftSide = styled('div', {
  display: 'flex',
  'flex-direction': 'row',
  'align-items': 'flex-start',
  'align-self': 'flex-start',
  'margin-top': '5px'
});

const NavRightSide = styled('div', {
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'flex-end',
  'align-self': 'flex-start',
  'margin-top': '1rem'
});

const NavRightTop = styled('div', {
  display: 'flex',
  height: '30px',
  'align-items': 'center',
  'flex-direction': 'row',
  'padding-bottom': `${pxToRem(6)};
  > * {
    margin: 0 0.3rem;
  }`
});

const Hr = styled('hr', {
  width: '100%',
  height: '1px',
  border: '0px',
  'border-radius': '0px',
  background: 'linear-gradient(90deg,rgb(255, 255, 255) 0%,rgb(218, 219, 220) 100%)',
});

const ProfileName = styled('p', {
  'color': '$UHC_BLUE',
  'font-family': `$UHC_PRIMARY`,
  'margin': 0,
  'font-weight': 'bold',
  'line-height': '24px'
});

const PageHeading = styled('div', {
  color: '#002677',
  fontFamily: 'UHCSerifHeadline-Semibold',
  fontSize: '18px',
  fontWeight: 600,
  height: '40px',
  lineHeight: '40px',
  textAlign: 'left',
  marginTop: '20px',
  marginBottom: '10px',
});

const DropdownContent = styled('div', {
  'background-color': 'white',
  cursor: 'pointer',
  'color': `$primary`,
  fontWeight: 'bold',
  'height': '24px',
  'display': 'flex',
  'align-items': 'center',
  'font-size': '16px'
});

const StyledPopup = styled(Popover, {
  'align-items': 'center',
  '&-content': {
    'margin': 'auto',
    'padding': '0.4rem 2rem',
    'background-color': `$UHC_WHITE`,
    'box-shadow': `0px 0px 4px 0px rgba(51, 51, 51, 0.3)`,
    'border-radius': '5px',
    'color': `$primary`,
    'font-weight': 'bold',
    'width': '125px'
  },

  '&-arrow': {
    'stroke-width': '2px',
    'stroke': `rgba(51, 51, 51, 0.2)`,
    'stroke-dasharray': '30px',
    'stroke-dashoffset': '-54px'
  }
});

export const Header = () => {
  const { signout, signoutDMZ, user } = useAuth();
  return (
    <HeaderStyle>
      <PageLayout>
        <NavBar>
          <NavLeftSide>
            <UHC src={PRELogo} alt="UHC Logo" />
            <PageHeading id="pageHeading">
              PRE M&R - Find PCP Recommendations
            </PageHeading>
          </NavLeftSide>
          <NavRightSide>
            <StyledPopup
              content={
                <DropdownContent onClick={isDMZ() ? signoutDMZ : signout}>
                  Sign Out
                </DropdownContent>
              }
              position="bottom"
              showClose={false}
            >
              <NavRightTop id="nav-right-top">
                <UserIcon />
                <ProfileName>
                  {isDMZ() ? user?.email : user?.displayname}
                </ProfileName>
                <DropdownIcon />
              </NavRightTop>
            </StyledPopup>
            <Hr />
          </NavRightSide>
        </NavBar>
      </PageLayout>
    </HeaderStyle>
  );
};
