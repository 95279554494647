import { useEffect, useState } from 'react';
import { ProgressBar as AbyssProgressBar } from '@abyss/web/ui/ProgressBar';
import { styled } from '@abyss/web/tools/styled';
import PRE_LOGO from 'assets/svg/new_pre_logo.svg';



const ProgressBar = styled(AbyssProgressBar,{
  'width': '25%',
  'align-self': 'center'
});

const FlexWrapper = styled('div',{
  display: 'flex',
  'flex-direction': 'column',
  'background':` $gray150`,
  'justify-content': 'center',
  'align-items': 'center',
  'height': '100vh',
  'margin': 'auto'})
;

const Logo = styled('img',{
  height: '8rem',
  width: '12rem',
  'align-self': 'center'})
;

const GlobalLoader = () => {
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 1500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <FlexWrapper>
      <Logo src={PRE_LOGO} alt="PRE2.0 LOGO" />
      <ProgressBar
        fillColor="#002677"
        percentage={progress}
        borderRadius={10}
        width="25%"/>
    
     </FlexWrapper>
  );
};

export default GlobalLoader;
