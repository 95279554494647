export const PREConstant = {
  MOCKUP_URL: 'http://localhost:3003',
  INVALID_INPUT: 'Invalid Input',
  GENDER: {
    M: 'M',
    MALE: 'Male',
    FEMALE: 'Female',
    U: 'U'
  },
  ERROR_TITLE: {
    ERROR: 'Error',
    NO_RECORDS_FOUND: 'No Records found'
  },
  ERROR_MSG: {
    MANDATORY_FIELDS_ERR: 'Please enter values for mandatory fields.',
    INVALID_ZIPCODE: 'Enter valid Zip Code with 5 digits',
    COUNTIES_FETCH_ERR: 'Error occurred while fetching Counties',
    PLAN_NAME_ERR: 'Error occurred while fetching Plan Names',
    PROVIDER_FETCH_ERR: 'Error occurred while fetching Providers.'
  },
  LANGUAGE: {
    ENG: 'ENG',
    DEFAULT_LANGUAGE: 'English'
  },
  CLIENT_NAME: {
    WEBPAGE: 'WebPage',
    DMZ: 'DMZ'
  },
  headers: {
    'Access-Control-Allow-Origin': '*'
  },
  CLOSE_ALERT: 'close-pre-overlay',
  OPEN_ALERT: 'show-pre-overlay',
  GENDER_OPTION: [
    { label: 'No Preference', value: 'U' },
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' }
  ],
  SECOND_LANG: [
    { value: 'ENG', label: 'English' },
    { value: 'ABK', label: 'Abkhazian' },
    { value: 'ACH', label: 'Acoli' },
    { value: 'AEE', label: 'Northeast PashayI' },
    { value: 'AFA', label: 'Afroasiatic (Other)' },
    { value: 'AFE', label: 'Afar' },
    { value: 'AFR', label: 'Afrikaans' },
    { value: 'AKA', label: 'Akan' },
    { value: 'ALB', label: 'Albanian (Tosk)' },
    { value: 'AMH', label: 'Amharic' },
    { value: 'APA', label: 'Apache' },
    { value: 'ARA', label: 'Arabic' },
    { value: 'ARM', label: 'Armenian' },
    { value: 'ASL', label: 'American Sign Languag' },
    { value: 'ASM', label: 'Assamese' },
    { value: 'ASY', label: 'Assyrian' },
    { value: 'AUS', label: 'Austronesian' },
    { value: 'AUV', label: 'Occitan' },
    { value: 'AYM', label: 'Aymara' },
    { value: 'AZE', label: 'Azerbaijani' },
    { value: 'BAL', label: 'Balochi' },
    { value: 'BAM', label: 'Bambara' },
    { value: 'BCY', label: 'Bislama' },
    { value: 'BEL', label: 'Belarusian' },
    { value: 'BEN', label: 'Bengali' },
    { value: 'BHJ', label: 'Bihari' },
    { value: 'BIS', label: 'Bisaya' },
    { value: 'BJE', label: 'Biao Jiao Mien' },
    { value: 'BJT', label: 'Balanta-Ganja' },
    { value: 'BLA', label: 'Siksika' },
    { value: 'BLG', label: 'Bulgarian' },
    { value: 'BOS', label: 'Bosnian' },
    { value: 'BRA', label: 'Braille' },
    { value: 'BRT', label: 'Breton' },
    { value: 'BSQ', label: 'Basque' },
    { value: 'BUR', label: 'Burmese' },
    { value: 'BXR', label: 'Bashkir' },
    { value: 'CAI', label: 'Tewa' },
    { value: 'CAM', label: 'Cambodian' },
    { value: 'CAN', label: 'Cantonese' },
    { value: 'CDO', label: 'Foochow' },
    { value: 'CEB', label: 'Cebuano-Visayan' },
    { value: 'CHA', label: 'Chamorro' },
    { value: 'CHI', label: 'Chinese' },
    { value: 'CLN', label: 'Catalan' },
    { value: 'COI', label: 'Corsican' },
    { value: 'CPF', label: 'Creoles And Pidgins' },
    { value: 'CRE', label: 'Creole' },
    { value: 'CRO', label: 'Croatian' },
    { value: 'CZE', label: 'Czech' },
    { value: 'CZH', label: 'Chiu Chow' },
    { value: 'DAK', label: 'Dakota' },
    { value: 'DAN', label: 'Danish' },
    { value: 'DIN', label: 'Twi' },
    { value: 'DIV', label: 'Dhivehi' },
    { value: 'DOI', label: 'Dogri' },
    { value: 'DUT', label: 'Dutch Flemish' },
    { value: 'DZO', label: 'Dzongkha' },
    { value: 'EGY', label: 'Egyptian' },
    { value: 'EPO', label: 'Esperanto' },
    { value: 'ESG', label: 'Greenlandic' },
    { value: 'ESI', label: 'Inupiaq' },
    { value: 'ESK', label: 'Eskimo' },
    { value: 'EST', label: 'Estonian' },
    { value: 'EWE', label: 'Ewe' },
    { value: 'FAO', label: 'Faroese' },
    { value: 'FAR', label: 'Farsi' },
    { value: 'FIL', label: 'Filipino' },
    { value: 'FIN', label: 'Finnish' },
    { value: 'FJL', label: 'Fiji' },
    { value: 'FRE', label: 'French' },
    { value: 'FRS', label: 'Frisian' },
    { value: 'GA~', label: 'Ga~' },
    { value: 'GAE', label: 'Gaelic' },
    { value: 'GAX', label: '(Afan) Oromo' },
    { value: 'GBZ', label: 'Dari' },
    { value: 'GEM', label: 'Germanic (Other)' },
    { value: 'GEO', label: 'Georgian' },
    { value: 'GER', label: 'German' },
    { value: 'GLH', label: 'Northwest Pashayi' },
    { value: 'GLN', label: 'Galician' },
    { value: 'GRE', label: 'Greek' },
    { value: 'GUJ', label: 'Gujarati' },
    { value: 'GUN', label: 'Guarani' },
    { value: 'GYN', label: 'Guyanese' },
    { value: 'HAK', label: 'Hakka Chinese' },
    { value: 'HAT', label: 'Haitian Creole' },
    { value: 'HEB', label: 'Hebrew' },
    { value: 'HIN', label: 'Hindi' },
    { value: 'HLA', label: 'Halia' },
    { value: 'HMN', label: 'Hmong' },
    { value: 'HMO', label: 'Hiri Motu' },
    { value: 'HUA', label: 'Hausa' },
    { value: 'HUN', label: 'Hungarian' },
    { value: 'HWI', label: 'Hawaiian' },
    { value: 'IBO', label: 'Ibo' },
    { value: 'ICE', label: 'Icelandic' },
    { value: 'IGB', label: 'Igbo' },
    { value: 'ILK', label: 'Ilongot' },
    { value: 'ILO', label: 'Ilocano' },
    { value: 'INO', label: 'Indonesian' },
    { value: 'IRA', label: 'Iranian' },
    { value: 'IRI', label: 'Irish' },
    { value: 'ITA', label: 'Italian' },
    { value: 'ITS', label: 'Itsekiri' },
    { value: 'JAM', label: 'Jamaican Patois' },
    { value: 'JAP', label: 'Japanese' },
    { value: 'JAV', label: 'Javanese' },
    { value: 'KAR', label: 'Karen' },
    { value: 'KAZ', label: 'Kazakh' },
    { value: 'KIK', label: 'Kikuyu' },
    { value: 'KIR', label: 'Kyrgyz' },
    { value: 'KIS', label: 'Kiswahili' },
    { value: 'KJG', label: 'Khmu' },
    { value: 'KJV', label: 'Kannada' },
    { value: 'KNN', label: 'Konkani' },
    { value: 'KOR', label: 'Korean' },
    { value: 'KSH', label: 'Kashmiri' },
    { value: 'KTU', label: 'Kituba' },
    { value: 'KUR', label: 'Kurdish' },
    { value: 'LAO', label: 'Laotian' },
    { value: 'LAT', label: 'Latin' },
    { value: 'LAV', label: 'Latvian' },
    { value: 'LEB', label: 'Lebanese Arabic' },
    { value: 'LIN', label: 'Lingala' },
    { value: 'LIT', label: 'Lithuanian' },
    { value: 'LKT', label: 'Lakota Sioux' },
    { value: 'LTZ', label: 'Luxembourger' },
    { value: 'LUG', label: 'Luganda' },
    { value: 'LUT', label: 'Lushootseed-Puyallup' },
    { value: 'MAH', label: 'Marshallese-Ebon' },
    { value: 'MAI', label: 'Maithili' },
    { value: 'MAN', label: 'Mandarin' },
    { value: 'MAR', label: 'Marathi' },
    { value: 'MBF', label: 'Maori' },
    { value: 'MJS', label: 'Malayalam' },
    { value: 'MKH', label: 'Khmer' },
    { value: 'MLG', label: 'Malagasy' },
    { value: 'MLI', label: 'Malay' },
    { value: 'MLS', label: 'Maltese' },
    { value: 'MNI', label: 'Manipuri' },
    { value: 'MNW', label: 'Mon' },
    { value: 'MOH', label: 'Mohawk' },
    { value: 'MON', label: 'Mongolian' },
    { value: 'MTI', label: 'Medical Interp Vendor' },
    { value: 'NAI', label: 'Keres' },
    { value: 'NAN', label: 'Min-Nan Chinese' },
    { value: 'NAV', label: 'Dine/Navajo' },
    { value: 'NDE', label: 'Ndebele' },
    { value: 'NEP', label: 'Nepali' },
    { value: 'NOR', label: 'Norwegian' },
    { value: 'NSO', label: 'Northern Sotho' },
    { value: 'NZE', label: 'Nzema' },
    { value: 'OJI', label: 'Ojibwa' },
    { value: 'ORM', label: 'Oromo' },
    { value: 'ORO', label: 'Oromiffa' },
    { value: 'ORT', label: 'Oriya' },
    { value: 'PBT', label: 'Pashtu' },
    { value: 'PBU', label: 'Pushto' },
    { value: 'PER', label: 'Persian' },
    { value: 'POH', label: 'Pohnpeian' },
    { value: 'POL', label: 'Polish' },
    { value: 'POR', label: 'Portuguese' },
    { value: 'PRC', label: 'Parachi' },
    { value: 'PSH', label: 'Pashayi' },
    { value: 'PSI', label: 'Southeast Pashayi' },
    { value: 'PST', label: 'Pashto' },
    { value: 'PUN', label: 'Punjabi' },
    { value: 'PYD', label: 'Pennsylvania Dutch' },
    { value: 'QEC', label: 'Quechua' },
    { value: 'ROH', label: 'Romansh' },
    { value: 'ROM', label: 'Romanian' },
    { value: 'RUA', label: 'Kinyarwanda' },
    { value: 'RUD', label: 'Kirundi' },
    { value: 'RUM', label: 'Moldavian' },
    { value: 'RUS', label: 'Russian' },
    { value: 'SAM', label: 'Samoan' },
    { value: 'SAN', label: 'Sanskrit' },
    { value: 'SAT', label: 'Santali' },
    { value: 'SBN', label: 'Sindhi' },
    { value: 'SCO', label: 'Scottish' },
    { value: 'SCY', label: 'Scanian' },
    { value: 'SER', label: 'Serbian' },
    { value: 'SHA', label: 'Shanghainese' },
    { value: 'SIT', label: 'Bodo' },
    { value: 'SLA', label: 'Slavic' },
    { value: 'SLO', label: 'Slovak' },
    { value: 'SLV', label: 'Slovene' },
    { value: 'SNA', label: 'Shona' },
    { value: 'SNH', label: 'Sinhalese' },
    { value: 'SOM', label: 'Somali' },
    { value: 'SON', label: 'Songhai' },
    { value: 'SOT', label: 'Sotho' },
    { value: 'SPA', label: 'Spanish' },
    { value: 'SRH', label: 'Tajik' },
    { value: 'SRR', label: 'Serer' },
    { value: 'SSO', label: 'Sesotho' },
    { value: 'SSW', label: 'Swazi' },
    { value: 'SWA', label: 'Swahili' },
    { value: 'SWE', label: 'Swedish' },
    { value: 'SWZ', label: 'Siswati' },
    { value: 'SYR', label: 'Syrian' },
    { value: 'TAG', label: 'Tagalog' },
    { value: 'TAI', label: 'Taiwanese' },
    { value: 'TAM', label: 'Tamil' },
    { value: 'TCK', label: 'Turkmen' },
    { value: 'TCY', label: 'Tulu' },
    { value: 'TDD', label: 'Tdd Machine' },
    { value: 'TEL', label: 'Telugu' },
    { value: 'TGN', label: 'Tigrinya' },
    { value: 'THA', label: 'Thai' },
    { value: 'TNZ', label: 'Tonga' },
    { value: 'TOW', label: 'Towa' },
    { value: 'TPI', label: 'Tok Pisin' },
    { value: 'TRK', label: 'Macedonian' },
    { value: 'TRU', label: 'Truk' },
    { value: 'TSH', label: 'Tshiluba' },
    { value: 'TSO', label: 'Tsonga' },
    { value: 'TSW', label: 'Tswana (Setswana)' },
    { value: 'TTR', label: 'Tatar' },
    { value: 'TUM', label: 'Tumbuka' },
    { value: 'TUR', label: 'Turkish' },
    { value: 'TZM', label: 'Tamazight' },
    { value: 'UKR', label: 'Ukrainian' },
    { value: 'UMB', label: 'Umbundu' },
    { value: 'URD', label: 'Urdu' },
    { value: 'UZB', label: 'Uzbek' },
    { value: 'VEN', label: 'Venda' },
    { value: 'VIE', label: 'Vietnamese' },
    { value: 'WLS', label: 'Welsh' },
    { value: 'WOF', label: 'Wolof' },
    { value: 'XOS', label: 'Xhosa' },
    { value: 'YID', label: 'Yiddish' },
    { value: 'YIE', label: 'Nuosu' },
    { value: 'YOR', label: 'Yoruba' },
    { value: 'YUE', label: 'Taishanese' },
    { value: 'ZUL', label: 'Zulu' },
    { value: 'ZUN', label: 'Zuni' },
    { value: 'CNR', label: 'Montenegrin' },
    { value: 'CHE', label: 'Chechen' },
    { value: 'SUN', label: 'Sundanese' },
    { value: 'TIB', label: 'Tibetan' },
    { value: 'UIG', label: 'Uighur' }
  ]
};
