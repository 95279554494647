/* eslint-disable eqeqeq */
/* eslint-disable global-require */
module.exports = {
  getCurrentEnvironmentConfigs() {
    if (process.env.REACT_APP_ENVIRONMENT) {
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'prod') {
        return require('./environment.prod.json');
      }
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'prod-elr') {
        return require('./environment.prod-elr.json');
      }
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'stage') {
        return require('./environment.stage.json');
      }
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'stage-elr') {
        return require('./environment.stage-elr.json');
      }
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'dev') {
        return require('./environment.dev.json');
      }
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'test1') {
        return require('./environment.test1.json');
      }
      if (process.env.REACT_APP_ENVIRONMENT.trim() === 'test2') {
        return require('./environment.test2.json');
      }
    }
    return require('./environment.local.json');
  }
};
/* eslint-enable global-require */
