import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { ThemeProvider } from '@abyss/ui/styles/ThemeProvider';
// import { AbyssStyles } from '@abyss/ui/styles/AbyssStyles';
// import { global } from '@abyss/ui/tools/styled';
import { ThemeProvider as AbyssThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { pxToRem } from 'utils/formatFontSize';
import { createTheme } from '@abyss/web/tools/theme';
import store from './redux/store';

// import { theme } from './theme/index';
import './index.css';
// import { GlobalStyles } from './globalStyles/index';

import App from './App';

// const Styles = global`${GlobalStyles}`;

const themeOverride = {
  theme: {
    colors: {
      primary: '#196ECF',
      secondary: '#002677',
      warning: '#DB9C00',
      tertiary: '#E5F8FB',
      error: '#c40000',
      success: '#007000',

      // Border
      border_light_gray: '#dbdbdb',
      gray150:'#EEEEEE',

      // UHC Palette
      UHC_BLUE: '#002677',
      UHC_WHITE: '#FFFFFF',
      UHC_BRIGHT_BLUE: '#00BED5',
      UHC_GOLD: '#F5B700',
      UHC_ORANGE: '#FF681F',
      UHC_CTA_BLUE: '#196ECF',
      UHC_CTA_ORANGE: '#D14600',
      UHC_CTA_DARK_ORANGE: '#AA3600',
      UHC_BRIGHT_BLUE_10: '#E5F8FB',
      UHC_BRIGHT_BLUE_20: '#CCF2F7',
      UHC_BRIGHT_BLUE_40: '#99E5EE',
      UHC_GRAY_DARK: '#333333',
      UHC_GRAY_MEDIUM: '#DADBDC',
      UHC_GRAY_LIGHT: '#F4F4F4'
    },
    fonts: {
      UHC_PRIMARY: 'UHCSans, Arial, sans-serif',
      UHC_HEADING: 'UHCSerif, Georgia Bold, serif',

      // Font size globally
      body1Size: pxToRem(16)
    },
  },
};

const uhcTheme = createTheme('uhc', themeOverride);
// eslint-disable-next-line 
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <AbyssThemeProvider theme={uhcTheme}>
      {/* <ThemeProvider theme={theme}> */}
        {/* <AbyssStyles /> */}
        {/* <Styles /> */}
        <App />
      {/* </ThemeProvider> */}
      </AbyssThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
