/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';

import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { styled } from '@abyss/web/tools/styled';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { styles } from './providerSearch/ProviderSearch.styles';
import { getEffectiveDateList, validate, states, GridColumnName, getCurrentTime, getUserName, getClientName, getLanguageFullNameByCode } from '../utils/PreUtils';
import { PREConstant } from '../constant/PREConstants';
import Loading from '../components/Loading/Loading';
import useGetCounty from '../hooks/useGetCounty';
import useGetPlanName from '../hooks/useGetPlanName';
import useGetProviders from '../hooks/useGetProviders';
import useMBI from '../hooks/useMBI';
import { Container, FieldRow, LoadingDiv, ButtonRow, EmptyResult } from './StyledElement'
import OverlayMessage from '../components/OverlayMessage';
import useAuth from '../hooks/useAuth';

// const GridCSS = `{
//     th[data-testid]={
//         background-color: '#002677'
//     }
// }`;

const ProviderSearchCriteria = () => {
  const [errors, setErrors] = React.useState(validate({}));
  const [errorMessage, setErrorMessage] = React.useState('');
  const [medicareBenId, setMedicareBenId] = React.useState({
    value: null,
    hasError: false,
    touched: false
  });
  const [effectiveDate, setEffectiveDate] = React.useState('');
  const [effectiveDateOption] = React.useState(getEffectiveDateList);
  const [memberAddress, setMemberAddress] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [state, setState] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const [county, setCounty] = React.useState('');
  const { countyOptions, setCountyOptions, loading, countyApiError } = useGetCounty(zipCode);
  const [planName, setPlanName] = React.useState('');
  const { planNameOptions, setPlanNameOptions, planNameLoading, planNameApierror } = useGetPlanName(effectiveDate.trim(), county.trim());
  const [preferredSecLang, setPreferredSecLang] = React.useState(PREConstant.LANGUAGE.ENG);
  const [preferredGender, setPreferredGender] = React.useState(PREConstant.GENDER.U);
  const [payload, setPayload] = React.useState('');
  const { gridData, providerLoading, noProvidersFlag, showGridData, providerApiError } = useGetProviders(payload);
  const { user } = useAuth();
  const { changeHandler, blurHandler, medicareBenIdhook, setMedicareBenIdhook, errorMessageMedicareIDHook } = useMBI(medicareBenId);

  const SubmitButton = styled('button', {
    background: '$UHC_BLUE',
    color: '$UHC_WHITE',
    'font-size': '16px',
    'line-height': '16px',
    border: `2px solid $UHC_BLUE`,
    'border-radius': '2rem',
    'font-weight': '700',
    'padding': '11px 16px',
  });

  const ResetButton = styled('button', {
    background: '$UHC_WHITE',
    color: '$UHC_BLUE',
    'font-size': '16px',
    'line-height': '16px',
    border: `2px solid $UHC_BLUE`,
    'border-radius': '2rem',
    'font-weight': '700',
    'padding': '11px 16px',
    'margin-right': '10px',
  });

  let dataTableProps = {};

  useEffect(() => {
    if (providerApiError.response !== undefined) {
      setErrorMessage(providerApiError);
    }
  }, [providerApiError])

  useEffect(() => {
    if (gridData !== undefined && gridData.length > 0) {
      dataTableProps.setData(gridData, true);
    }
  }, [gridData])

  useEffect(() => {

    if (countyApiError !== undefined && countyApiError !== null && countyApiError.response !== undefined) {
      setErrorMessage(countyApiError);
    }
  }, [countyApiError])

  useEffect(() => {
    if (planNameApierror.response !== undefined) {
      setErrorMessage(planNameApierror);
    }
  }, [planNameApierror])

  useEffect(() => {
    if (medicareBenIdhook.hasError && medicareBenIdhook.touched) {
      setErrorMessage(errorMessageMedicareIDHook);
    }
  }, [errorMessageMedicareIDHook])

  function validateAndSubmit() {
    setMedicareBenId(currentValue => ({
      ...currentValue,

    }));
    const payloadRequest = {
      memberMedicareId: medicareBenIdhook.value, memberEffectiveDate: effectiveDate.trim(), memberAddress1: memberAddress, memberCity: city, memberState: state,
      memberZip: zipCode, memberCounty: county.split('-')[0].trim(), memberPlanName: planName.split('__')[0].trim(), memberLanguage: getLanguageFullNameByCode(preferredSecLang),
      memberGender: preferredGender, memberGroupId: planName.split('__')[1], memberPlanId: planName.split('__')[3], memberDiv: planName.split('__')[2],
      clientName: getClientName(), memberCountyFips: county.split('-')[1], memberUsername: getUserName(user)
    };
    const validationErrors = validate(payloadRequest);
    if (!validationErrors.error) {
      setErrors(validationErrors);
      setPayload(payloadRequest);
    } else {
      setErrors(validationErrors);
      setPayload('');
      if (validationErrors.errors.memberZip.errorMessage) {
        setErrorMessage(`${validationErrors.errors.memberZip.errorMessage}-${getCurrentTime()}`);
      }
      else if (validationErrors.errors.memberMedicareId.errorMessage) {
        setErrorMessage(`${validationErrors.errors.memberMedicareId.errorMessage}-${getCurrentTime()}`);
      }
      else {
        setErrorMessage(`${PREConstant.ERROR_MSG.MANDATORY_FIELDS_ERR}-${getCurrentTime()}`);
      }
    }
  }

  function reset() {
    setMedicareBenIdhook({
      value: '',
      hasError: false,
      touched: false,
    });
    setEffectiveDate('');
    setMemberAddress(null);
    setCity(null);
    setState('');
    setZipCode('');
    setCounty('');
    setPlanName('');
    setPreferredSecLang(PREConstant.LANGUAGE.ENG);
    setPreferredGender(PREConstant.GENDER.U);
    setErrors(validate({}));
    setPayload('');
    setCountyOptions([]);
    setPlanNameOptions([]);
  }

  const columns = React.useMemo(
    () => GridColumnName,
    []
  );

  dataTableProps = useDataTable({
    initialColumns: columns,
    pageSizeOptions: [10, 25, 50],
    showPagination: true,
    uniqueStorageId: 'pre-pcp-recommendations-data-table-usage',
  });

  return (
    <Container>
      {errorMessage && <OverlayMessage alertType="error" alertTitle="Error" alertMessage={errorMessage} />}
      {(loading || planNameLoading || providerLoading) && (<LoadingDiv className="show-pre-overlay"> <Loading /> </LoadingDiv>)}
      <FieldRow>
        <span htmlFor="medicare-ben-id" style={errors.error && errors.errors.memberMedicareId.error ? styles.errorLabel : styles.label}>
          <span style={styles.mandatory}>*</span>Medicare Number (MBI) </span>
        <TextInput value={medicareBenIdhook.value}
          onChange={changeHandler}
          onBlur={blurHandler}
          autoComplete="off"
          id="medicare-ben-id"
          data-testid="medicare-ben-id"
          validators={{ required: (value) => !value }}
          maxLength={11}
          width="375px"
        />

      </FieldRow>
      <FieldRow>
        <span htmlFor="effective-date" style={errors.error && errors.errors.memberEffectiveDate.error ? styles.errorLabel : styles.label} >
          <span style={styles.mandatory}>*</span>Proposed Effective Date
        </span>
        <SelectInput
          onChange={setEffectiveDate}
          value={effectiveDate}
          placeholder="---Not Specified---"
          width="360px"
          options={effectiveDateOption}
          validators={{ required: (value) => !value }}
          data-testid="startFrom"
          optionHeight="36px"
        />
      </FieldRow>
      <FieldRow>
        <span htmlFor="mem-add" style={styles.labelCSS}> <span style={styles.labelNonMandatory}>&nbsp;</span>Applicant Address </span>
        <TextInput value={memberAddress} onChange={(e) => setMemberAddress(e.target.value)} autoComplete="off" data-testid="mem-add" validators={{ required: (value) => !value }} width="375px" />
      </FieldRow>
      <FieldRow>
        <span htmlFor="city" style={styles.labelCSS}> <span style={styles.labelNonMandatory}>&nbsp;</span>City </span>
        <TextInput value={city} onChange={(e) => setCity(e.target.value)} autoComplete="off" data-testid="city" validators={{ required: (value) => !value }} width="375px" />
      </FieldRow>
      <FieldRow>
        <span htmlFor="state" style={errors.error && errors.errors.memberState.error ? styles.errorLabel : styles.label} >
          <span style={styles.mandatory}>*</span>State
        </span>
        <SelectInput
          onChange={setState}
          value={state}
          placeholder="---Not Specified---"
          options={states}
          width="360px"
          validators={{ required: (value) => !value }}
          data-testid="state"
          optionHeight="36px"
          isSearchable


        />
      </FieldRow>
      <FieldRow>
        <span htmlFor="zip-code" style={errors.error && errors.errors.memberZip.error ? styles.errorLabel : styles.label} >
          <span style={styles.mandatory}>*</span>Zip Code
        </span>
        <TextInput
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          onBlur={(e) => setZipCode(e.target.value)}
          onKeyDown={(e) => setZipCode(e.target.value)}
          autoComplete="off"
          id="zipCode"
          data-testid="zipCode"
          validators={{ required: (value) => !value }}
          maxLength={5}
          width="375px"

        />
      </FieldRow>
      <FieldRow>
        <span htmlFor="county" style={errors.error && errors.errors.memberCounty.error ? styles.errorLabel : styles.label} >
          <span style={styles.mandatory}>*</span>County
        </span>
        <SelectInput
          onChange={setCounty}
          value={county}
          placeholder="---Not Specified---"
          options={countyOptions}
          width="360px"
          id="county"
          data-testid="county"
          optionHeight="36px"
          isSearchable


        />
      </FieldRow>
      <FieldRow>
        <span htmlFor="product-name" style={errors.error && errors.errors.memberPlanName.error ? styles.errorLabel : styles.label} >
          <span style={styles.mandatory}>*</span>Proposed Plan Name
        </span>
        <SelectInput
          onChange={setPlanName}
          value={planName}
          placeholder="---Not Specified---"
          options={planNameOptions}
          width="360px"
          data-testid="productName"
          optionHeight="36px"
          isSearchable
        />
      </FieldRow>
      <FieldRow>
        <span htmlFor="pref-lang" style={styles.labelCSS}><span style={styles.labelNonMandatory}>&nbsp;</span>Preferred Physician Language </span>
        <SelectInput
          onChange={setPreferredSecLang}
          value={preferredSecLang}
          placeholder="English"
          options={PREConstant.SECOND_LANG}
          width="360px"
          data-testid="pref-lang"
          optionHeight="36px"
          isSearchable


        />
      </FieldRow>
      <FieldRow>
        <span htmlFor="pref-gender" style={styles.labelCSS}><span style={styles.labelNonMandatory}>&nbsp;</span>Preferred Physician Gender </span>
        <SelectInput
          onChange={setPreferredGender}
          value={preferredGender}
          placeholder="---Not Specified---"
          options={PREConstant.GENDER_OPTION}
          width="360px"
          data-testid="pref-gender"
          optionHeight="36px"
        />
      </FieldRow>
      <ButtonRow>
        <ResetButton onClick={() => reset()}>Reset</ResetButton>
        <SubmitButton onClick={() => validateAndSubmit()}>Submit</SubmitButton>
      </ButtonRow>
      {showGridData && (<DataTable
        css={{
          'abyss-table-styled-header': {
            backgroundColor: '$UHC_BLUE',
          },
          'abyss-table-header-data-container': {
            color: '$UHC_WHITE',
            'padding': '16px 4px 16px 16px;'
          },
          'abyss-data-table-subheader-lower-container': {
            '> div': {
              'margin': '0px',
              'width': '100%'
            },
            'padding': '10px 15px',
            'border-color': '#B2B2B2',
            'border-style': 'solid',
            'border-top-width': '3px'
          },
          'abyss-table-root': {
            'width': '100 %',
            'font-size': '14px',
            'line-height': '16px',
            'border-color': '#B2B2B2',
            'border-style': 'solid',
            'border-width': '1px',
            'border-spacing': '0',
            'border-top-width': '3px',
            'border-bottom-width': '3px',
          },
          'abyss-table-cell': {
            'padding': '16px'
          },
          'abyss-table-row': {
            '&:hover': { 'background-color': '#cce6ff' }
          },
          'abyss-data-table-pagination-top-root': {
            '> div': {
              'padding': '0px'
            },
            'width': '100%',
            'justify-content': 'space-between',
            'flex-direction': 'row-reverse',
            'align-items': 'center'
          },
          'abyss-pagination-results-text': {
            fontWeight: '$bold'
          },
          'abyss-pagination-page-size-label': {
            'font-weight': '600'
          }
        }}
        tableState={dataTableProps} hideTitleHeader />)}
      {noProvidersFlag && <EmptyResult>No PCPs found based on search criteria.</EmptyResult>}
      {noProvidersFlag && (<style>{`div[class*='paginationBottom']{display:none;}`}</style>)}
    </Container>
  );
};
export default ProviderSearchCriteria;
