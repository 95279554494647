/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInitialize, getInitializeDMZ } from '../../redux/slices/auth';
import { isDMZ } from '../../utils/commonUtil';

export default function JwtProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDMZ()) {
      dispatch(getInitializeDMZ());
    } else {
      dispatch(getInitialize());
    }
  }, [dispatch]);

  return children;
}

JwtProvider.propTypes = {
  children: PropTypes.node
};
