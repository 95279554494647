/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import { isDMZ, getHeaders } from '../utils/commonUtil';
import { getCurrentTime } from '../utils/PreUtils';

const getCountyByZipCodeURL = (zipCode, countiesSuffix, apiUrl) => {
  let url = '';
  const parameters = `?zipCode=${zipCode}`;
  if (isDMZ()) {
    url = `${countiesSuffix}${parameters}`;
  } else {
    url = `${apiUrl}/counties${parameters}`;
  }
  return url;
};

const useGetCounty = (zipCode = '') => {

  const [countyOptions, setCountyOptions] = useState([]);
  const [countyApiError, setCountyApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    apiUrl,
    countiesSuffix,
    preDMZBearerToken
  } = useAuth();
  const fetchData = () => {
    if (zipCode !== undefined && zipCode.length === 5 && !/^[0-9]+$/.test(zipCode)) {
      setCountyApiError(`Please enter valid Zip Code.-${getCurrentTime()}`);
    } else if (zipCode !== undefined && zipCode !== '' && zipCode.length === 5) {
      setLoading(true);
      const url = getCountyByZipCodeURL(zipCode, countiesSuffix, apiUrl);
      const headers = getHeaders(preDMZBearerToken);
      axios.get(url, headers)
        .then((res) => {
          const temp = [];
          res.data.counties.forEach((element) => {
            temp.push({
              label: element.name,
              value: `${element.name}-${element.fips}-${element.stateFips}`
            });
          });
          setCountyOptions(temp);
        })
        .catch((err) => {
          setCountyApiError(err);
        })
        .finally(() => { setLoading(false) });
    }
  };

  useEffect(() => {
    fetchData();
  }, [zipCode]);

  return { countyOptions, setCountyOptions, countyApiError, loading };
};
export default useGetCounty;
