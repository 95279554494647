import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import useAuth from 'hooks/useAuth';
import isEmpty from 'lodash.isempty';
import { useDispatch } from 'react-redux';
import { getToken, getTokenDMZ } from '../../redux/slices/auth';
import GlobalLoading from '../GlobalLoading';
import { isDMZ } from '../../utils/commonUtil';

const OAuth = () => {
  const { error, isAuthenticated } = useAuth();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthentication = async () => {
      const queryParams = qs.parse(search, {
        ignoreQueryPrefix: true
      });
      if (queryParams.code && queryParams.code !== undefined) {
        try {
          if (isDMZ()) {
            dispatch(getTokenDMZ(queryParams.code));
          } else {
            dispatch(getToken(queryParams.code));
          }
        } catch (err) {
          navigate('/serverError', { replace: true });
        }
      } else {
        navigate('/providersearch', { replace: true });
      }
    };
    handleAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated && isEmpty(error)) {
      navigate('/providersearch', { replace: true });
    } else if (!isEmpty(error) && error.code === '02') {
      navigate('/unauthorized', { replace: true });
    }
  }, [isAuthenticated, error]);
  return <GlobalLoading />;
};

export default OAuth;
