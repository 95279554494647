// eslint-disable-next-line no-unused-vars
import React, { Suspense, lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import GlobalLoader from '../components/GlobalLoading';
import OAuth from '../components/Authentication/OAuth';
import AuthGuard from '../guards/AuthGuard';
import DashboardLayout from '../layouts/DashboardLayout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<GlobalLoader />}>
      <Component {...props} />
    </Suspense>
  );

function Router() {
  return useRoutes([
    {
      path: '/',
      element: <OAuth />
    },
    {
      path: 'unauthorized',
      element: <UnAuthorized />
    },
    {
      path: 'providersearch',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      )
      // children: [
      //   {
      //     path: '/providersearch',
      //     element: <Navigate to="/providersearch" replace />
      //   },
      //   { path: 'providersearch', element: <SearchFields /> }
      // ]
    }
  ]);
}
export default Router;

const UnAuthorized = Loadable(lazy(() => import('views/AccessDenied')));
