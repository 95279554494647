/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import { isDMZ, getHeaders } from '../utils/commonUtil';
import { PREConstant } from '../constant/PREConstants';

import { getStateNameByCode, getPreferredLanguage } from '../utils/PreUtils';

const getProviderSearchURL = (apiUrl, providerSuffix) => {
  let url = '';
  if (isDMZ()) {
    url = `${providerSuffix}`;
  } else {
    url = `${apiUrl}/member-provider-recommendations`;
  }
  return url;
};

const returnState = (element) =>
  element.state ? getStateNameByCode(element.state) : '';


const returnPhoneNo = (element) =>
  element.phoneAreaCode
    ? `${element.phoneAreaCode}${element.phoneNumber}`
    : '';


const returnGender = (element) =>
  element.gender === PREConstant.GENDER.M
    ? PREConstant.GENDER.MALE
    : PREConstant.GENDER.FEMALE;

const returnLanguage = (element, prefLang) =>

  element.languages
    ? getPreferredLanguage(element.languages, prefLang)
    : PREConstant.ENG;

const useGetProviders = (payload = '') => {

  const [gridData, setGridData] = useState([]);
  const [showGridData, setShowGridData] = useState(false);
  const [providerApiError, setProviderApiError] = useState('');
  const [providerLoading, setProviderLoading] = useState(false);
  const [noProvidersFlag, setNoProvidersFlag] = useState(false);
  const {
    apiUrl,
    providerSuffix,
    preDMZBearerToken
  } = useAuth();

  const fetchData = () => {
    if (payload !== '') {
      setProviderLoading(true);
      const prefLang = payload.memberLanguage;
      const url = getProviderSearchURL(apiUrl, providerSuffix);
      const headers = getHeaders(preDMZBearerToken);
      axios.post(url, payload, headers)
        .then((res) => {
          const temp = [];
          if (!res.data.providers.length) {
            setNoProvidersFlag(true);
          } else {
            setNoProvidersFlag(false);
          }
          res.data.providers.forEach((element) => {
            temp.push({
              providerId: element.providerId,
              fullName: `${element.firstName} ${element.middleName} ${element.lastName}`,
              groupName: element.groupName,
              address: `${element.addressLine1} ${element.addressLine2}`,
              city: element.city,
              state: returnState(element),
              zip: element.zip,
              county: element.county,
              phoneNumber: returnPhoneNo(element),
              distance: element.distance,
              speciality: element.speciality,
              gender:returnGender(element),
              language: returnLanguage(element, prefLang)
            });
          });
          setGridData(temp);
          setShowGridData(true);
        })
        .catch((err) => {
          setProviderApiError(err);
        })
        .finally(() => {
          setProviderLoading(false);
        });
    } else {
      setShowGridData(false);
      setNoProvidersFlag(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  return { gridData, providerApiError, providerLoading, noProvidersFlag, showGridData };
};
export default useGetProviders;
