/* eslint-disable no-underscore-dangle */
import axios from 'axios';
/*
import Cookies from 'js-cookie';
*/
import useAuth from '../hooks/useAuth';


export const AppInit = () => {
  const { signoutDMZ, refreshTokenDMZ } = useAuth();

  /*
  axios.interceptors.request.use(
    (config)=>{
      const newconfig=config;
      const csrfToken = Cookies.get('XSRF-TOKEN'); // Get the CSRF token from your Redux store
      newconfig.headers['X-CSRF-TOKEN'] = csrfToken;
      return newconfig;

    }
  )
  */

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        originalRequest.url.includes('/api/dmz/refreshToken')
      ) {
        signoutDMZ();
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await refreshTokenDMZ();
        } catch (e) {
          signoutDMZ();
        }
        const retryRequest = {
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: axios.defaults.headers.common.Authorization
          }
        };
        return axios(retryRequest);
      }
      return Promise.reject(error);
    }
  );
};
