/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { authErrorCodes } from '../../utils/messagesUtil';
import { roles as systemRoles } from '../../utils/roles';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isInitialized: false,
    isAuthenticated: false,
    apiUrl: '',
    isLoggedOut: false,
    user: {},
    roles: [],
    error: {},
    dmzApiBaseUrl: '',
    providerSuffix: '',
    plansSuffix: '',
    countiesSuffix: '',
    preDMZBearerToken: ''
  },
  reducers: {
    getInitialize(state, action) {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.roles = action.payload.roles;
    },
    getInitializeDMZ(state, action) {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.roles = [];
    },
    loginSuccess(state, action) {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.roles = action.payload.roles;
      state.error = action.payload.error;
      state.apiUrl = action.payload.apiUrl;
      state.dmzApiBaseUrl = action.payload.dmzApiBaseUrl;
      state.providerSuffix = action.payload.providerSuffix;
      state.plansSuffix = action.payload.plansSuffix;
      state.countiesSuffix = action.payload.countiesSuffix;
      state.preDMZBearerToken = action.payload.preDMZBearerToken;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.roles = [];
      state.isLoggedOut = true;
    }
  }
});

export default authSlice.reducer;

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const setSessionAccessToken = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setSessionRefreshToken = (theRefreshToken) => {
  if (theRefreshToken) {
    sessionStorage.setItem('refreshToken', theRefreshToken);
  } else {
    sessionStorage.removeItem('refreshToken');
  }
};

export const setSessionIdToken = (theIdToken) => {
  if (theIdToken) {
    sessionStorage.setItem('idToken', theIdToken);
  } else {
    sessionStorage.removeItem('idToken');
  }
};

export const setStargateToken = (theStargateToken) => {
  if (theStargateToken) {
    sessionStorage.setItem('stargateToken', theStargateToken);
  } else {
    sessionStorage.removeItem('stargateToken');
  }
};

export function logout() {
  return async (dispatch) => {
    setSessionAccessToken(null);
    dispatch(authSlice.actions.logoutSuccess());
  };
}

export function logoutDMZ() {
  return async (dispatch) => {
    setSessionAccessToken(null);
    setSessionRefreshToken(null);
    setSessionIdToken(null);
    dispatch(authSlice.actions.logoutSuccess());
  };
}

export function getToken(code) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/auth/token', { code });
      // eslint-disable-next-line camelcase
      const { access_token, id_token, apiUrl } = response.data;
      const decodedInfo = jwtDecode(id_token);
      const { roles } = decodedInfo;
      if (roles.includes(systemRoles[0])) {
        setSessionAccessToken(access_token);
        dispatch(
          authSlice.actions.loginSuccess({
            isAuthenticated: true,
            user: decodedInfo,
            roles: [roles],
            apiUrl
          })
        );
      } else {
        dispatch(
          authSlice.actions.loginSuccess({
            isAuthenticated: false,
            user: null,
            roles: [],
            error: authErrorCodes.unauthorizedRole
          })
        );
      }
    } catch (error) {
      dispatch(
        authSlice.actions.loginSuccess({
          isAuthenticated: false,
          user: null,
          error: authErrorCodes.serverUnauthorize
        })
      );
      throw new Error(error);
    }
  };
}

export function getTokenDMZ(code) {

  return async (dispatch) => {
    try {
      const response = await axios.post('/api/dmz/token', { code });
      // eslint-disable-next-line camelcase
      const {
        // eslint-disable-next-line camelcase
        access_token,
        // eslint-disable-next-line camelcase
        id_token,
        // eslint-disable-next-line camelcase
        refresh_token,
        apiUrl,
        dmzApiBaseUrl,
        providerSuffix,
        plansSuffix,
        countiesSuffix,
        preDMZBearerToken
      } = response.data;
      const decodedInfo = jwtDecode(id_token);
      setSessionAccessToken(access_token);
      setSessionRefreshToken(refresh_token);
      setSessionIdToken(id_token);
      dispatch(
        authSlice.actions.loginSuccess({
          isAuthenticated: true,
          user: decodedInfo,
          apiUrl,
          dmzApiBaseUrl,
          providerSuffix,
          plansSuffix,
          countiesSuffix,
          preDMZBearerToken
        })
      );
    } catch (error) {
      dispatch(
        authSlice.actions.loginSuccess({
          isAuthenticated: false,
          user: null,
          error: authErrorCodes.serverUnauthorize
        })
      );
      throw new Error(error);
    }
  };
}

export function refreshToken() {
  return async (dispatch) => {
    const response = await axios.post('/api/auth/refreshToken');
    // eslint-disable-next-line camelcase
    const { access_token } = response.data;
    setSessionAccessToken(access_token);
  };
}

export function refreshTokenDMZ() {
  return async (dispatch) => {
    const response = await axios.post('/api/dmz/refreshToken');
    // eslint-disable-next-line camelcase
    const { access_token, refresh_token } = response.data;
    setSessionAccessToken(access_token);
    setSessionRefreshToken(refresh_token);
  };
}

export function getInitialize() {
  return async (dispatch) => {
    try {
      const accessToken = window.sessionStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const { data: userInfo, apiUrl } = await axios.get(
          '/api/auth/userinfo',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        setSessionAccessToken(accessToken);
        dispatch(
          authSlice.actions.getInitialize({
            isAuthenticated: true,
            user: userInfo,
            apiUrl,
            roles: [userInfo.roles] // currently coming in string, with multiple it will be in an array
          })
        );
      } else {
        dispatch(
          authSlice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
            roles: []
          })
        );
      }
    } catch (error) {
      dispatch(
        authSlice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
          roles: []
        })
      );
    }
  };
}

export function getInitializeDMZ() {
  return async (dispatch) => {
    try {
      const accessToken = window.sessionStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const { data: userInfo, apiUrl } = await axios.get(
          '/api/dmz/userinfo',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        setSessionAccessToken(accessToken);
        dispatch(
          authSlice.actions.getInitialize({
            isAuthenticated: true,
            user: userInfo,
            apiUrl,
            roles: []
          })
        );
      } else {
        dispatch(
          authSlice.actions.getInitializeDMZ({
            isAuthenticated: false,
            user: null,
            roles: []
          })
        );
      }
    } catch (error) {
      dispatch(
        authSlice.actions.getInitializeDMZ({
          isAuthenticated: false,
          user: null,
          roles: []
        })
      );
    }
  };
}
