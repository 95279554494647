export const styles = {
  errorLabel: {
    fontWeight: 500,
    color: 'red',
    width: '300px',
    fontSize: '15px'
  },
  label: {
    fontWeight: 500,
    width: '300px',
    fontSize: '15px'
  },
  mandatory: {
    color: 'red',
    marginRight: '5px'
  },
  labelNonMandatory: {
    marginRight: '9px'
  },
  labelCSS: {
    width: '300px',
    fontSize: '15px'
  }
};
