import { styled } from '@abyss/web/tools/styled';
import { Header } from './Header';
import { Footer } from './Footer';
import ProviderSearchCriteria from '../../preComponents/ProviderSearchCriteria';

const MainContainer = styled('div', {
  background: `$gray150`,
  // 'padding-bottom': 30,
  'flex': 1,
  'min-height': 'calc(100vh - 138px)'
});

const Body = styled('div', {
  'display': 'flex',
  'flex-direction': 'column',
  'margin': '0 auto',
});

const DashboardLayout = () => (
  <Body>
    <Header />
    <MainContainer>
      <ProviderSearchCriteria />
    </MainContainer>
    <Footer />
  </Body>
);

export default DashboardLayout;
