/* eslint-disable no-unused-vars */
import { useState, useRef } from 'react';
// import { styled } from '@abyss/core/tools/styled';
import { styled } from '@abyss/web/tools/styled';
import { Modal } from '@abyss/web/ui/Modal';
import { Layout } from '@abyss/web/ui/Layout';
// import { Modal } from '@abyss/ui/base/Modal';
import { useIdleTimer } from 'react-idle-timer';
import useAuth from 'hooks/useAuth';
import CountDownTimer from '../CountDownTimer/CountDownTimer';
import { isDMZ } from '../../utils/commonUtil';

const ModalWrapper = styled('div', {
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'center',
  'justify-content': 'center',
  'padding': '1rem',
});

const BodyText = styled('p', {
  width: '100%'
});

const TimeRemaining = styled('h4', {
  'font-weight': '600 !important',
  'margin-right': '0.5rem',
  'color': 'black !important'
});

const TimeWrapper = styled('div', {
  display: 'flex',
  'align-items': 'center',
  'justify-content': 'center',
  'width': '100%',
  'padding-top': '1rem'
});

const ContinueButton = styled('button', {
  background: '$UHC_BLUE',
  color: '$UHC_WHITE',
  'font-size': '1rem',
  'padding': '0.7rem 1rem',
  'line-height': '1.1rem',
  border: `2px solid $UHC_BLUE`,
  'border-radius': '2rem',
  'font-weight': 'bold',
  'min-width': '13rem',
  'background-position': 'left',
  transition: 'background 0.1s',

  '&:hover': {
    border: `2px solid $primary`,
    background: `$primary`,
    color: `$UHC_WHITE`
  }
});

const SignoutButton = styled('button', {
  background: '$UHC_WHITE',
  color: '$UHC_BLUE',
  'font-size': '1rem',
  'padding': '0.7rem 1rem',
  'line-height': '1.1rem',
  border: `2px solid $UHC_BLUE`,
  'border-radius': '2rem',
  'font-weight': 'bold',
  'min-width': '8rem',
  'background-position': 'left',
  transition: 'background 0.1s',

  '&:hover': {
    border: `2px solid $primary`,
    background: `$primary`,
    color: `$UHC_WHITE`
  }
});
const IdleSession = () => {
  const { signout, signoutDMZ, refreshToken, refreshTokenDMZ } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const handleLogout = () => {
    setIsModalOpen(false);
    if (isDMZ()) {
      signoutDMZ();
    } else {
      signout();
    }
    clearTimeout(sessionTimeoutRef.current);
  };

  const handleOnIdle = () => {
    setIsModalOpen(true);
    sessionTimeoutRef.current = setTimeout(handleLogout, 120000);
  };

  const stayLoggedIn = () => {
    setIsModalOpen(false);
    if (isDMZ()) {
      refreshTokenDMZ();
    } else {
      refreshToken();
    }
    clearTimeout(sessionTimeoutRef.current);
  };

  useIdleTimer({
    timeout: 1500000,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true
    },
    ref: { idleTimerRef }
  });

  return (
    <Modal
      title="Session Timeout Warning"
      titleAlign="center"
      isOpen={isModalOpen}
      size="full"
      closeOnClickOutside={false}
      closeOnEscPress={false}
      hideClose
      css={{
        'abyss-modal-content-container': { width: '650px' },
        'abyss-modal-header-container': {
          'border-bottom': '3px solid rgb(229, 229, 229)',
          'padding': '20px'
        },
        'abyss-modal-content-title': {
          'padding': '0px',
          'font-size': '34px',
          'line-height': '34px',
          'font-weight': '600',
          'font-family': '$abyss-fonts-primary !important'
        },
        'abyss-modal-footer': {
          'border-top': '1px solid rgb(229, 229, 229)'
        }
      }}
      footer={
        < Modal.Section >
          <Layout.Group alignLayout="center" >
            <ContinueButton onClick={stayLoggedIn}>
              Continue Working
            </ContinueButton>
            <SignoutButton
              onClick={handleLogout}
            >
              Sign Out
            </SignoutButton>
          </Layout.Group>
        </Modal.Section >
      }
    >
      <Modal.Section>
        <ModalWrapper>
          <BodyText>
            You will be signed out in a few minutes due to inactivity. All
            unsaved data on this page will be lost.
          </BodyText>
          <TimeWrapper>
            <TimeRemaining>Time remaining: </TimeRemaining>
            <CountDownTimer initialMinute={2} initialSeconds={0} />
          </TimeWrapper>
        </ModalWrapper>
      </Modal.Section>
    </Modal >
  );
};

export default IdleSession;
