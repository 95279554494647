import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';


const CountDownTimer = (props) => {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const TimeRemaining = styled('h4', {
    'font-weight': '600 !important',
    'color': 'black !important'
  });

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <TimeRemaining>
          {' '}
          {minutes}:{`0${seconds}`.slice(-2)}
        </TimeRemaining>
      )}
    </div>
  );
};

export default CountDownTimer;

CountDownTimer.propTypes = {
  initialMinute: PropTypes.number.isRequired,
  initialSeconds: PropTypes.number.isRequired
};
