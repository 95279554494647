import ReactLoading from 'react-loading';

const Loading = () => (
  <ReactLoading
    type="spokes"
    color="#002677"
    height="0%"
    width="10%"
    className="loading-css"
  />
);

export default Loading;
