import { styled } from '@abyss/web/tools/styled';
import { PageLayout } from '../../../components/PageLayout';

const FooterText = styled('div', {
  'text-align': 'center',
  width: '100%',
  'padding-top': '10px',
  'padding-bottom': '10px',
  'font-size': '13px',
  'color': 'white',
  height: '50px',
  'line-height': '24px'
});

const RightsReserved = styled('span', {
  'margin-left': '30px'
})
  ;

const FooterBar = styled('div', {
  'background-color': `$UHC_BLUE`,
  /* grid-column: 1 / -1; */
  'grid-area': 'footer'
})
  ;

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterBar>
      <PageLayout>
        <FooterText>
          &#169; {year} United Healthcare Services. Inc.
          <RightsReserved>All Rights Reserved</RightsReserved>
        </FooterText>
      </PageLayout>
    </FooterBar>
  );
};
