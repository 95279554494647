import { styled } from '@abyss/web/tools/styled';
import { RiCloseFill } from 'react-icons/ri';

export const Container = styled('div',{
  padding: '10px'
});

export const FieldRow = styled('div',{
  display: 'flex',
  flexDirection: 'row',
  height: '25px',
  justifyContent: 'start',
  marginBottom: '10px',
  alignItems:'end'

});

export const LoadingDiv = styled('div',{
  position: 'fixed',
  top: '0%',
  left: '0%',
  width: '100%',
  height: '100%',
  backgroundColor: '#fffff',
  opacity: '.8',
  zIndex: 1001
});

export const OverlayDiv = styled('div',{
  position: 'fixed',
  display: 'none',
  width: '60%',
  left: '22%',
  zIndex: 1,
  cursor: 'pointer'
});

export const CloseIcon = styled(RiCloseFill,{
  color: `$UHC_GRAY_DARK`,
  height:'1.5rem',
  width: '1.5rem',
  cursor: 'pointer',
  float: 'right'
});



export const ButtonRow = styled('div',{
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: '10px',
});

export const EmptyResult = styled('div',{
  display: 'flex',
  flexDirection: 'row',
  height: '25px',
  justifyContent: 'center',
  marginTop: '10px',
  color: '#333333'
});