import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  logout,
  logoutDMZ,
  refreshToken as refreshTokenAsync,
  refreshTokenDMZ as refreshTokenDMZAsync
} from '../redux/slices/auth';

const environmentSettings =
  require('../services/config/load.config').getCurrentEnvironmentConfigs();

const useAuth = () => {
  const {
    isAuthenticated,
    apiUrl,
    dmzApiBaseUrl,
    providerSuffix,
    plansSuffix,
    countiesSuffix,
    preDMZBearerToken,
    isInitialized,
    error,
    isLoggedOut,
    user
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const redirectToSSO = () => {
    axios.get('/api/auth/redirect').then((response) => {
      window.location = response.data.url;
    });
  };

  const redirectToDMZ = () => {
    axios.get('/api/dmz/redirect').then((response) => {
      window.location = response.data.url;
    });
  };

  const signout = async () => {
    await dispatch(logout());
    axios.get('/api/auth/logout').then((response) => {
      window.location = response.data.url;
    });
  };

  const signoutDMZ = async () => {
    await dispatch(logoutDMZ());
    axios.get('/api/dmz/logout').then(() => {
      window.location = environmentSettings.oidc.logout.landing;
    });
  };

  const refreshToken = async () => {
    await dispatch(refreshTokenAsync());
  };

  const refreshTokenDMZ = async () => {
    await dispatch(refreshTokenDMZAsync());
  };

  return {
    redirectToSSO,
    redirectToDMZ,
    signout,
    signoutDMZ,
    refreshToken,
    refreshTokenDMZ,
    isAuthenticated,
    isInitialized,
    isLoggedOut,
    error,
    user,
    apiUrl,
    dmzApiBaseUrl,
    providerSuffix,
    plansSuffix,
    countiesSuffix,
    preDMZBearerToken
  };
};

export default useAuth;
