import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { isDMZ } from '../utils/commonUtil';

export default function AuthProtect({ children }) {
  const { redirectToSSO, redirectToDMZ, isAuthenticated, isLoggedOut } =
    useAuth();
  if (!isAuthenticated && !isLoggedOut) {
    if (isDMZ()) {
      redirectToDMZ();
    } else {
      redirectToSSO();
    }
  }

  return isAuthenticated && children;
}

AuthProtect.propTypes = {
  children: PropTypes.node.isRequired
};
