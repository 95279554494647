import axios from 'axios';

const MIN = 0;
const MAX = 100;

export const normalise = (value) =>
  (((value - MIN) * 100) / (MAX - MIN)).toFixed(0);

export const getFirstName = (name) => {
  if (!name) {
    return null;
  }
  const regexName = name.replace(/\s*,\s*|\s+(?=\S+$)/g, '#');
  return regexName.split('#')[1];
};

export const getLastName = (fullName) => {
  if (!fullName) {
    return null;
  }

  const regexName = fullName.replace(/\s*,\s*|\s+(?=\S+$)/g, '#');
  return regexName.split('#')[0];
};

export const formatBytes = (bytes, decimals = 2) => {
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const isDMZ = () => !document.URL.includes('-web');

export const getHeaders = (preDMZBearerToken) => {
  let headersToReturn = 'foobar';
  if (isDMZ()) {
    axios.defaults.headers.common = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${preDMZBearerToken}`
    };  
    const headers = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${preDMZBearerToken}`
    };
    headersToReturn = headers;
  } else {
    headersToReturn = {'Access-Control-Allow-Origin': '*'};
  }
  return headersToReturn;
}; 
