import { PREConstant } from 'constant/PREConstants';
import { isDMZ } from './commonUtil';

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export const Gender = [
  { label: 'No Preference', value: 'U' },
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
];

export const GridColumnName = [
  { Header: 'Provider ID', accessor: 'providerId' },
  { Header: 'Name', accessor: 'fullName' },
  { Header: 'Group Name', accessor: 'groupName'},
  { Header: 'Address', accessor: 'address' },
  { Header: 'City', accessor: 'city' },
  { Header: 'State', accessor: 'state' },
  { Header: 'ZIP', accessor: 'zip' },
  { Header: 'County', accessor: 'county' },
  { Header: 'Phone', accessor: 'phoneNumber' },
  { Header: 'Distance', accessor: 'distance' },
  { Header: 'Specialty', accessor: 'speciality' },
  { Header: 'Gender', accessor: 'gender' },
  { Header: 'Language', accessor: 'language' }
];

export const AlertType = {
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success'
};

const checkMembermedicaidid=(enteredVal)=>

   enteredVal.length !== 11 ||
          !/^[a-zA-Z0-9]{11}/.test(enteredVal) || 
          !/^((?![SLOIBZsliobz]).)*$/.test(enteredVal);


const checkZip=(trimmed)=>

    trimmed.length < 1 ||
    trimmed.length > 8 ||
    !/^[0-9]+$/.test(trimmed)

const checkZip2=(trimmed)=>
trimmed.length !== 0 && !/^[0-9]+$/.test(trimmed)
       


export const validate = (values) => {
  let error = false;
  const errors = {
    memberMedicareId: {},
    memberEffectiveDate: {},
    memberZip: {},
    memberState: {},
    memberCounty: {},
    memberPlanName: {}
  };
  Object.keys(values).forEach((fieldKey) => {
    const field = values[fieldKey];
    switch (fieldKey) {
      case 'memberMedicareId':
        if (field) {
        const enteredVal = field.trim();
        if (checkMembermedicaidid(enteredVal)) {            
          error = true;
          errors.memberMedicareId.error = true;
          errors.memberMedicareId.errorMessage = 'Please enter valid medicare number';
          }
          else {
            errors.memberMedicareId.errorMessage = '';
          }
        }
        else {          
          error = true;
          errors.memberMedicareId.error = true;
        }
        break;
      case 'memberEffectiveDate':
        if (!field) {
          error = true;
          errors.memberEffectiveDate.error = true;
        }
        break;
      case 'memberZip':
        if (!field) {
          error = true;
          errors.memberZip.error = true;
          errors.memberZip.errorMessage = '';
        } else {
          const trimmed = field.trim();
          if (checkZip(trimmed)) {
            error = true;
            errors.memberZip.error = true;
            if (checkZip2(trimmed)) {
              errors.memberZip.errorMessage = 'Please enter valid Zip Code.';
            } else {
              errors.memberZip.errorMessage = '';
            }
          }
        }
        break;
      case 'memberState':
        if (!field) {
          error = true;
          errors.memberState.error = true;
        }
        break;
      case 'memberCounty':
        if (!field) {
          error = true;
          errors.memberCounty.error = true;
        }
        break;
      case 'memberPlanName':
        if (!field) {
          error = true;
          errors.memberPlanName.error = true;
        }
        break;
      default:
    }
  });
  return { errors, error };
};

export const getStateNameByCode = (stateCode) => {
  let stateName;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < states.length; i++) {
    if (states[i].value === stateCode) {
      stateName = states[i].label;
      break;
    }
  }
  return stateName;
};

export const getPreferredLanguage = (langList, prefLang) => {
  let language = 'ENG';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < langList.length; i++) {
    if (
      prefLang !== PREConstant.LANGUAGE.DEFAULT_LANGUAGE &&
      langList[i].fullName.toUpperCase() === prefLang.toUpperCase()
    ) {
      language = `${langList[i].shortName}, ENG`;
      break;
    }
  }
  return language;
};

export const getLanguageFullNameByCode = (languageCode) => {
  const languageFullName = PREConstant.SECOND_LANG.find(lang => lang.value === languageCode);
  return languageFullName.label;
}

export const getTime = () => {
  const d = new Date();
  return d.getTime();
};

export const getEffectiveDateList = () => {
  let date = new Date();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const list = [];
  for (let i = 1; i <= 3; i += 1) {
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month === 11) {
      date = new Date(year + 1, 0, 1);
    } else {
      date = new Date(year, month + 1, 1);
    }
    const temp = `0${date.getMonth() + 1}`.slice(-2);
    list.push({
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: `${date.getFullYear()}-${temp}-01`
    });
  }
  return list;
};

export const getCurrentTime = () => {
  const date = new Date();
  return date.getTime();
}

export const getUserName = (user) => (isDMZ() ? user.email : user.sub);

export const getClientName = () =>
  isDMZ()
    ? PREConstant.CLIENT_NAME.WEBPAGE.concat(PREConstant.CLIENT_NAME.DMZ)
    : PREConstant.CLIENT_NAME.WEBPAGE;
