// eslint-disable-next-line no-unused-vars
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import JwtProvider from './components/Authentication/JwtProvider';
import useAuth from './hooks/useAuth';
import Router from './routes/index';
import IdleSession from './components/Authentication/IdleSession';
import GlobalLoading from './components/GlobalLoading';
import { AppInit } from './utils/axiosInterceptor';

function App() {
  const { isInitialized } = useAuth();
  AppInit();

  return (
    // <Provider store={store}>
    //   <ThemeProvider theme={theme}>
    //     <AbyssStyles />
    //     <Styles />
    //     <Router>{renderRoutes(routes)}</Router>
    //   </ThemeProvider>
    // </Provider>
    <BrowserRouter>
      <JwtProvider>
        {isInitialized ? <Router /> : <GlobalLoading />}
        <IdleSession />
      </JwtProvider>
    </BrowserRouter>
  );
}

export default App;
