/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import { isDMZ, getHeaders } from '../utils/commonUtil';

const getPlanNameURL = (effectiveDate, county, plansSuffix, apiUrl) => {
  let url = '';
  const parameters = `?groupEffectiveDate=${effectiveDate.split('-')[0]}-01-01&countyFipsCode=${county.split('-')[1]}&stateFipsCode=${county.split('-')[2]}`;
  if (isDMZ()) {
    url = `${plansSuffix}${parameters}`;
  } else {
    url = `${apiUrl}/plans${parameters}`;
  }
  return url;
};

const useGetPlanName = (effectiveDate = '', county = '') => {
  const {
    apiUrl,
    plansSuffix,
    preDMZBearerToken
  } = useAuth();
  const [planNameOptions, setPlanNameOptions] = useState([]);
  const [planNameApierror, setPlanNameApierror] = useState('');
  const [planNameLoading, setPlanNameLoading] = useState(false);
  const url = getPlanNameURL(effectiveDate, county, plansSuffix, apiUrl);
  const headers = getHeaders(preDMZBearerToken);

  const fetchData = () => {
    if ((effectiveDate !== undefined && effectiveDate !== '') && (county !== undefined && county !== '')) {
      setPlanNameLoading(true);
      axios
        .get(url, headers)
        .then((res) => {
          const temp = [];
          res.data.plans.forEach((element) => {
            temp.push({
              label: element.planName,
              value: `${element.planName}__${element.groupId}__${element.memberDiv}__${element.planId}`
            });
          });
          setPlanNameOptions(temp);
        })
        .catch((err) => {
          setPlanNameApierror(err);
        })
        .finally(() => { setPlanNameLoading(false) });
    }
  };

  useEffect(() => {
    fetchData();
  }, [effectiveDate, county]);

  return { planNameOptions, setPlanNameOptions, planNameApierror, planNameLoading };
};
export default useGetPlanName;
