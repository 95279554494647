// Constants - static messages
export const messages = {
  // Common

  internalServerError: {
    header: 'Internal server error',
    body: 'We are sorry, but something went wrong. Please try again.'
  }
};

export const authErrorCodes = {
  serverUnauthorize: {
    code: '01',
    message: 'Invalid auth code'
  },
  unauthorizedRole: {
    code: '02',
    message: 'Invalid user role'
  }
};
