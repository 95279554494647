/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { OverlayDiv } from '../../preComponents/StyledElement';
import { PREConstant } from '../../constant/PREConstants';

const OverlayMessage = ({ alertType, alertTitle, alertMessage }) => {
  const [overlayState, setOverlayState] = useState(PREConstant.CLOSE_ALERT);

  useEffect(() => {
    setOverlayState(PREConstant.OPEN_ALERT);
  }, [alertMessage]);
  return (
    <OverlayDiv className={overlayState} onClick={() => setOverlayState(PREConstant.closeAlert)}>
        {/* <Alert className="error_alert" variant={alertType}>
        <CloseIcon id="delete-icon" onClick={() => setOverlayState(PREConstant.closeAlert)}/>
        <Alert.Title className="error_title">{alertTitle}</Alert.Title>
        {alertMessage.response ? alertMessage.response.data.message + alertMessage.response.data.error : alertMessage.split('-')[0]}
        </Alert> */}
        <Alert className="error_alert" variant={alertType} onClose={() =>setOverlayState(PREConstant.closeAlert)}
        title={alertTitle}>
            {alertMessage.response ? alertMessage.response.data.message + alertMessage.response.data.error : alertMessage.split('-')[0]}
        </Alert>
    </OverlayDiv>
  );
};
export default OverlayMessage;
