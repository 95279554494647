import {useState} from 'react';
import {getCurrentTime} from '../utils/PreUtils';

const useMBI = (medicareId) => {
    const [medicareBenIdhook, setMedicareBenIdhook] = useState(medicareId);
    const [errorMessageMedicareIDHook, setErrorMessageMedicareIDHook] = useState('');
    const changeHandler = e => {        
        const inputValue = e.target.value.trim().toUpperCase();
        let hasError = false;
        if(inputValue){
        if (inputValue.length !== 11 ||
          !/^[a-zA-Z0-9]{11}/.test(inputValue) || 
        !/^((?![SLOIBZsliobz]).)*$/.test(inputValue)
        ) {
          hasError = true;      
        }    
      }
      setMedicareBenIdhook(currentValue => ({
        ...currentValue,
        value: inputValue,
        hasError,
      }))
    }
    
    const blurHandler = () => {
        setMedicareBenIdhook(currentValue => ({
            ...currentValue,
            touched: true,
          }))
        if (medicareBenIdhook.hasError) {     
            const err = 'Please enter valid medicare number';
            setErrorMessageMedicareIDHook(`${err}-${getCurrentTime()}`);
        }     
    }

    return {
        changeHandler,
        blurHandler,
        medicareBenIdhook,
        setMedicareBenIdhook,
        errorMessageMedicareIDHook
    } 
}

export default useMBI;